$(document).ready(function () {

    function handleRemoveError(target) {
        var messageHolder = target.siblings('.app-error');
        target.addClass('app-hidden');
        messageHolder.removeClass('app-hidden');
    }

    $(".app-payment-section .app-remove-cc-link").click(function(event) {
        var target = $(event.target);
        var removeUrl = target.data('remove-cc-url');
        $.ajax({
            type: "DELETE",
            url: removeUrl,
            success: function(response) {
                if ((response || {}).ok) {
                    document.location.reload(true);
                }
                else {
                    handleRemoveError(target);
                }
            },
            error: function() {
                handleRemoveError(target);
            }
        })
    });

});
