$(document).ready(function () {

    $(".selectize").selectize({});

    $(".input-group-checkbox-list").input_group_checkbox_list();

    // Submit form when a checkbox is checked
    $(".services-sidebar").find("input:checkbox").on("change", function () {
        $(".services-sidebar").find("form").submit();
    });

    $('.update-delivery-details-form').on('blur', 'input', function (ev) {
        var $theField = $(ev.target);
        if($theField.attr('name') === 'nie') {
            // validate company
            $.get('me/users/deliveries/register_details/verify/nie/' + $theField.val(), null, function (result) {
                $theField.parents('.form-group').removeClass('has-warning');
                $theField.parents('.form-group').addClass('has-success');
            }).fail(function() {
                $theField.parents('.form-group').removeClass('has-success');
                $theField.parents('.form-group').addClass('has-warning');
            });
        }
    });

    $('.registration-form').on('blur', 'input', function(ev) {
      var $theField = $(ev.target);
      registrationCompanyValidateFn($theField);
    });

    if ($("form.registration-form").length) {
      if (document.location.search.length > 0) {
        setTimeout(function () {
          registrationCompanyValidateFn($("#company"));
        }, 100);
      }
    }

  // COOKIES

  function loadOnConsent() {

    // GA
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-107846409-1';
    document.body.appendChild(script);
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'UA-107846409-1');

    // TAWK
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function() {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/5ab0cac5d7591465c708b8d5/default';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();

    $('.disable-without-consent').attr("disabled", false);

  }

  (function () {
    "use strict";

    var cookieAlert = document.querySelector(".cookiealert");
    var acceptCookies = document.querySelector(".acceptcookies");

    if (cookieAlert) {
      cookieAlert.offsetHeight; // Force browser to trigger reflow (https://stackoverflow.com/a/39451131)

      if (!getCookie("acceptCookies")) {
        cookieAlert.classList.add("show");
        $('.disable-without-consent').attr("disabled", true);
      }
      else {
        loadOnConsent();
      }
    }

    if (acceptCookies) {
      acceptCookies.addEventListener("click", function () {
        setCookie("acceptCookies", true, 60);
        cookieAlert.classList.remove("show");
        loadOnConsent();
      });
    }

  })();

// SAVE COOKIE

  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

});

function registrationCompanyValidateFn($theField) {
  if($theField.attr('name') === 'company') {
    // validate company
    var encodedValue = encodeURIComponent($theField.val());
    $.get('/auth/verify/company/' + encodedValue, null, function (result) {
      $theField.parent('.form-group').removeClass('has-warning');
      $theField.parent('.form-group').addClass('has-success');
    }).fail(function() {
      $theField.parent('.form-group').removeClass('has-success');
      $theField.parent('.form-group').addClass('has-warning');
    });
  }
}

// Attaches/detaches selected time slot to the order and regenerates
// table of available time slots based on data returned from server
function setTimeslotCheckBoxes(event)
{
    // Get input data from event
    var el = event.target;
    var orderId = event.data.orderId;
    var slotType = event.data.slotType;

    // Disable the checkbox after click until processing is done
    $(el).prop('disabled', true);

    // Get timeslot id and state and send it to server
    timeslot = $(el).parent().attr('id');
    value = $(el).prop('checked');
    price = $(el).parent().children('span').html();
    price = price.replace('€','');
    $.ajax({
        url: '/me/order/' +orderId + '/timeslot',
        type: 'POST',
        data: {
            _token: $('meta[name="csrf-token"]').attr('content'),
            slot: timeslot,
            selected: value,
            type: slotType,
            price: price
        },
        // Update table of available time slots
        success: function (result, status, xhr)
        {
            // For each row (system time slot)
            $('table > tbody > tr').each(function(index)
            {
                // Get all time slots for the row
                var row = $(this).children('td');
                var rowName = row.first().text();

                // From the server response get updated time slots for this maq
                slotForDays = result.data[rowName];
                slotForDays.forEach(function(slot, index)
                {
                    var timeslotEl = $(row[index+1]);
                    // if slot is available, add checkbox
                    if (slot)
                    {
                        var checked = (slot.selected) ? ' checked ' : ' ';
                        text = '<input type="checkbox" name="drop_price" class="drop_price"' + checked + '>&nbsp;<span>' + slot.price +'</span>';
                        timeslotEl.html(text);
                        timeslotEl.prop('id', slot.timeslot);
                        timeslotEl.find('input').bind('click', {orderId : orderId, slotType: slotType}, setTimeslotCheckBoxes);
                    }
                    // if slot is not available, add dash
                    else
                    {
                        timeslotEl.text('-');
                        timeslotEl.removeAttr('id')
                    }
                });
            });
        },
        error: function (xhr){

        },
    });
}

