(function ($) {
    $.widget("mayordomo.input_group_checkbox_list", {
        options: {
            name: null,
            element: null,
            summaryElement: null,
            type: "text",
            uri: null,
            additionalData: []
        },
        _create: function () {
            var options = this.options;
            var that = this;

            this.summaryElement = $(this).find(".selected-summary");

            // Get the checkboxes and add the selected ones to the summary
            var checkedValues = $(this.element).find("input:checkbox:checked").map(function () {
                return this.value;
            }).get();

            // Submit form when a checkbox is checked
            $(this.element).find("input:checkbox").on("change", function () {
                $(that.element).parents("form").submit();
            });
        }
    });
})(jQuery);